import gql from 'graphql-tag';

export const CHANGE_PASSWORD = gql`
mutation changePassword($input: changePasswordUserInput!) {
    changePasswordUser(input: $input) {
        user {
            id
            email
            password
        }
    }
}
`;


export const CREATE_USER = gql`
mutation createUser($input: createUserInput!) {
    createUser(input: $input) {
        user {
            id
            firstName
            lastName
            email
            password
        }
    }
}
`;

export const CREATE_TALENT = gql`
mutation createTalent($input: createTalentInput!) {
    createTalent(input: $input) {
        talent {
            id
            name
            twitterAccount
        }
    }
}
`;

export const DELETE_USERS = gql`
mutation deleteUser($input: deleteUserInput!) {
    deleteUser(input: $input) {
        users {
            id
            email
        }
    }
}
`;

export const DELETE_TALENTS_USER = gql`
mutation deleteTalentsUser($input: deleteTalentsUserInput!) {
    deleteTalentsUser(input: $input) {
        user {
            id
        }
    }
}
`;

export const ADD_TALENTS_USER = gql`
mutation addTalentsUser($input: addTalentsUserInput!) {
    addTalentsUser(input: $input) {
        user {
            id
        }
    }
}
`;

export const PASSWORD_RESET = gql`
mutation passwordReset($input: passwordResetUserInput!) {
    passwordResetUser(input: $input) {
        user {
            id
            email
            password
        }
    }
}`
;

export const UPDATE_YAHOO_NEWS_ARTICLE = gql`
mutation updateYahooNewsArticle($input: updateYahooNewsArticleInput!) {
    updateYahooNewsArticle(input: $input) {
        yahooNewsArticle {
            id
            labelFlag
            category
            labeledDate
            labeledBy {
                id
                firstName
                lastName
            }
        }
    }
}`
;

export const UPDATE_YAHOO_NEWS_COMMENT = gql`
mutation updateYahooNewsComment($input: updateYahooNewsCommentInput!) {
    updateYahooNewsComment(input: $input) {
        yahooNewsComment {
            id
            title
            comment
            category
            labelFlag
            labeledBy {
                id
                firstName
                lastName
            }
            labeledDate
            sentimentType
            commentType
            createdAt
            scoreBad
            scoreGood
        }
    }
}`
;

export const UPDATE_YOUTUBE_COMMENT = gql`
mutation updateYoutubeComment($input: updateYoutubeCommentInput!) {
    updateYoutubeComment(input: $input) {
        youtubeComment {
            id
            labelFlag
            sentiment
            publishedAt
            labeledBy {
                id
                firstName
                lastName
            }
        }
    }
}`

export const UPDATE_YOUTUBE_VIDEO = gql`
mutation updateYoutubeVideo($input: updateYoutubeVideoInput!) {
    updateYoutubeVideo(input: $input) {
        youtubeVideo {
            id
            labelFlag
            videoId
            labeledBy {
                id
                firstName
                lastName
            }
        }
    }
}`

export const UPDATE_TWITTER_SEARCH_RESULT = gql`
mutation updateTwitterSearchResult($input: updateTwitterSearchResultInput!) {
    updateTwitterSearchResult(input: $input) {
        twitterSearchResult {
            id
            createdDt
            followersCount
            text
            hoverText
            screenName
            labelFlag
            sentimentSign
        }
    }
}`
;

export const UPDATE_TALENT = gql`
mutation updateTalent($input: updateTalentInput!) {
    updateTalent(input: $input) {
        talent {
            id
            isScraped
            isVisible
        }
    }
}`
;
