import gql from 'graphql-tag';

export const GET_PRESIGNED_UPLOAD_URL = gql`
query presignedUploadUrl($objectKey: String!) {
    presignedUploadUrl(objectKey: $objectKey) {
        objectKey
        expiresAt
        uploadUrl
    }
}
`;

export const GET_POWERPOINT_FILES = gql`
query powerpointFiles {
    powerpointFiles
}
`;

export const GET_USERS = gql`
query userList {
    users {
        totalCount
        edges {
            node {
                id
                firstName
                lastName
                email
                admin
            }
        }
    }
}
`;

export const GET_TALENTS = gql`
    query getTalents($isVisible: Boolean!) {
        talents(isVisible: $isVisible) {
            edges {
                node {
                    id
                    name
                    isScraped
                    isVisible
                    users {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
    }
`;

export const GET_YAHOO_NEWS_ARTICLES = gql`
query yahooNewsArticleList($search: String!) {
    yahooNewsArticles(search: $search) {
        totalCount
        edges {
            node {
                id
                title
                content
                day
                comment
                category
                labeledBy {
                    id
                    firstName
                    lastName
                }
                labelFlag
            }
        }
    }
}
`;

export const GET_YAHOO_NEWS_COMMENTS = gql`
query yahooNewsCommentList($search: String!) {
    yahooNewsComments(search: $search) {
        totalCount
        edges {
            node {
                id
                title
                comment
                category
                labeledBy {
                    id
                    firstName
                    lastName
                }
                labelFlag
                sentimentType
                commentType
                createdAt
                scoreBad
                scoreGood
            }
        }
    }
}
`;

export const GET_YOUTUBE_COMMENTS = gql`
query youtubeCommentList($search: String!) {
    youtubeComments(search: $search) {
        totalCount
        edges {
            node {
                id
                videoId
                youtubeVideo {
                    id
                    title
                    talent {
                        id
                        name
                    }
                }
                labeledBy {
                    id
                    firstName
                    lastName
                }
                likeCount
                comment
                sentiment
                publishedAt
            }
        }
    }
}
`;

export const GET_YOUTUBE_VIDEOS = gql`
query youtubeVideoList($search: String!) {
    youtubeVideos(search: $search) {
        totalCount
        edges {
            node {
                id
                videoId
                title
                viewCount
                talent {
                    id
                    name
                }
                labeledBy {
                    id
                    firstName
                    lastName
                }
                youtubeComments {
                    id
                }
                publishedAt
                category
                channelId
                channelTitle
                description
                sentence
                labelFlag
                labeledDate
            }
        }
    }
}
`;

export const GET_TWITTER_SEARCH_RESULTS = gql`
query twitterSearchResultList($search: String!) {
    twitterSearchResults(search: $search) {
        totalCount
        edges {
            node {
                id
                followersCount
                createdDt
                text
                hoverText
                screenName
                labelFlag
                sentimentSign
            }
        }
    }
}
`;

export const AUTH_CHECK = gql`
query authCheck {
    users {
        totalCount
    }
}
`;
