import React from 'react';
import { Modal } from 'antd';

const SetTalentInvisibleModal = ({ visible, onConfirm, onCancel, talentName }) => {

    return (
        <Modal
            title={`タレント「${talentName}」を削除してもよろしいですか？`}
            visible={visible}
            onOk={onConfirm}
            onCancel={onCancel}
            okText="削除する"
            cancelText="キャンセル"
        >
            <p>この操作は元に戻すことはできません。</p>
        </Modal>
    );
};

export default SetTalentInvisibleModal;